<template>
  <div>
    <span class="title">编辑资产信息</span>
    <div class="content_item">
      <el-form label-position="top" label-width="80px" :model="formData">
        <el-row>
          <el-col :span="12">
            <el-form-item label="设备编码">
              <el-input
                size="small"
                class="form_item"
                v-model="formData.deviceCode"
              ></el-input>
              <!--                            <el-button size="small" class="button_upload import">加载设备</el-button>-->
            </el-form-item>
            <el-form-item label="资产编码">
              <el-input
                size="small"
                class="form_item"
                v-model="formData.assetCode"
              ></el-input>
            </el-form-item>
            <el-form-item label="RFID">
              <el-input
                size="small"
                class="form_item"
                v-model="formData.deviceRfId"
              ></el-input>
            </el-form-item>
            <el-form-item label="IMEI">
              <el-input
                disabled
                size="small"
                class="form_item"
                v-model="formData.deviceImei"
              ></el-input>
            </el-form-item>
            <el-form-item label="在线状态">
              <el-select
                clearable
                disabled
                class="form_item"
                v-model.number="formData.onlineStatus"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in disposeState"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="客户">
              <el-select
                clearable
                class="form_item"
                v-model="formData.customerId"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in customer"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id + ''"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="所属组织">
              <!-- <el-select
                clearable
                class="form_item"
                v-model="formData.deptId"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in dept"
                  :key="item.deptId"
                  :label="item.deptName"
                  :value="item.deptId"
                >
                </el-option>
              </el-select> -->
              <span id="organization">
                <select-tree
                  style="width:320px"
                  ref="selectTree"
                  v-if="loading.tree"
                  :props="props"
                  :value="formData.deptId"
                  placeholder="请选择组织"
                  :options="dept"
                  :clearable="true"
                  :accordion="true"
                  @getValue="getValue($event)"
                />
              </span>
            </el-form-item>
            <el-form-item label="设备状态">
              <el-select
                clearable
                class="form_item"
                v-model="formData.status"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in faultState"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="生产时间">
              <el-input
                disabled
                size="small"
                class="form_item"
                v-model="formData.productTime"
              ></el-input>
            </el-form-item>
            <el-form-item label="资产年限">
              <el-select
                clearable
                disabled
                class="form_item"
                v-model="formData.assetYear"
                placeholder="请选择"
              >
                <el-option
                  disabled
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="资产类型">
              <el-select
                clearable
                disabled
                class="form_item"
                v-model="formData.deviceType"
                placeholder="请选择"
              >
                <el-option
                  disabled
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="设备型号">
              <el-input
                disabled
                size="small"
                class="form_item"
                v-model="formData.deviceFeature"
              ></el-input>
            </el-form-item>
            <el-form-item label="当前地址">
              <!--                            <el-input size="small" class="form_item"  v-model="formData.type"></el-input>-->
              <el-select
                clearable
                size="small"
                class="form_item_site"
                v-model="formData.province"
                placeholder="省"
              >
                <el-option
                  v-for="item in provinces"
                  :key="item.value"
                  :label="item.label"
                  :value="item.label"
                />
              </el-select>
              <el-select
                clearable
                size="small"
                class="form_item_site"
                v-model="formData.city"
                placeholder="市"
              >
                <el-option
                  v-for="item in cityList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.label"
                />
              </el-select>
              <el-select
                clearable
                size="small"
                class="form_item_site"
                v-model="formData.street"
                placeholder="区/县"
              >
                <el-option
                  v-for="item in areaList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.label"
                />
              </el-select>
              <!-- <el-button size="small" class="button_upload import"
                >获取位置</el-button
              > -->
              <br />
              <el-input
                style="width: 320px;margin-top: 10px"
                type="textarea"
                :autosize="{ minRows: 4 }"
                maxlength="200"
                resize="none"
                placeholder="请输入详细地址"
                v-model="formData.detailedLocation"
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <el-button @click="cancel" class="button_cancel buttons">取消</el-button>
    <el-button class="button_preserve" @click="assetEdits">保存</el-button>
  </div>
</template>

<script>
import {
  clientName,
  // organization,
  queryDeptAll,
  assetEdit
} from "@/api/AssetManagement.js";
import provinces from "@/utils/district.json";
// import region from "@/componemts/region";
export default {
  name: "assetEdit",
  props: ["editDatas"],
  components: {
    // region
  },
  data() {
    return {
      //默认值设置，可为空
      defultAddress: {
        sheng: "陕西省",
        shi: "",
        qu: ""
      },
      dept: [],
      customer: [],
      formData: {
        deptId: ""
      },
      disposeState: [
        {
          value: "1",
          label: "在线"
        },
        {
          value: "2",
          label: "离线"
        }
      ],
      options: [],
      faultState: [
        {
          value: "1",
          label: "正常"
        },
        {
          value: "2",
          label: "维修"
        },
        {
          value: "3",
          label: "报废"
        }
      ],
      provinces: provinces,
      props: {
        value: "deptId",
        label: "deptName",
        children: "children"
      },
      loading: {
        tree: false
      }
    };
  },
  created() {
    this.formData = { ...this.$route.query };
    this.formData.productTime = this.func.formattingTime(
      this.formData.productTime
    );
    this.formData.deptId = parseInt(this.formData.deptId, 10);
    this.getclientName();
    this.getOrganization();
  },
  computed: {
    cityList() {
      const result = this.provinces.find(item => {
        return item.label === this.formData.province;
      });
      return result ? result.children : [];
    },
    areaList() {
      const result = this.cityList.find(item => {
        return item.label === this.formData.city;
      });
      return result ? result.children : [];
    }
  },
  watch: {
    "formData.province": {
      handler() {
        const result = this.cityList.find(item => {
          return item.label === this.formData.city;
        });
        if (!result) {
          this.formData.city = "";
          this.formData.street = "";
        }
      }
    },
    "formData.city": {
      handler() {
        const result = this.areaList.find(item => {
          return item.label === this.formData.street;
        });
        if (!result) {
          this.formData.street = "";
        }
      }
    }
  },
  methods: {
    getValue(value) {
      this.formData.deptId = value;
    },
    //取消编辑
    cancel() {
      this.$router.push("/dashboard/assetAdmin");
    },
    getclientName() {
      clientName({}).then(res => {
        this.customer = res.data.data;
      });
    },
    getOrganization() {
      const config = {
        params: {
          userId: localStorage.getItem("userId")
        }
      };
      queryDeptAll(config).then(({ data }) => {
        const arr = data.data || [];
        arr.forEach(value => {
          value.children = [];
          arr.forEach(val => {
            if (value.deptId === val.parentId) {
              value.children.push(val);
            }
          });
          // if (value.parentId === 0) {
          //   this.organization.push(value);
          // }
          if (value.deptId === parseInt(localStorage.getItem("deptId"), 10)) {
            this.dept.push(value);
          }
          this.loading.tree = true;
        });
      });

      // organization({}).then(res => {
      //   // this.dept = res.data.data;
      // });
    },
    assetEdits() {
      const form = { ...this.formData };
      form.productTime = new Date(this.$route.query.productTime).switch(
        "yyyy-MM-dd hh:mm:ss"
      );

      assetEdit(form).then(res => {
        if (res.data.desc == "SUCCESS" && res.data.resultCode == 0) {
          this.$message({
            showClose: true,
            message: "编辑成功！",
            type: "success",
            duration: 800
          });
          this.$router.push("/dashboard/assetAdmin");
        } else {
          this.$message({
            showClose: true,
            message: "编辑失败",
            type: "error",
            duration: 800
          });
        }
      });
    }
  }
};
</script>
<style scoped>
.form_item_site {
  width: 100px;
  margin-right: 10px;
}

.title {
  font-size: 16px;
  color: #000;
  margin-bottom: 20px;
  display: inline-block;
  margin-bottom: 20px;
}

.buttons {
  margin-left: 30px;
  margin-top: 20px;
}

.content_item {
  padding-left: 30px;
  padding-bottom: 30px;
  background: #fff;
}

.form_item {
  width: 320px;
}

.button_upload {
  width: 93px;
}

/*/deep/ .el-form-item__label{*/
/*    line-height: 20px;*/
/*}*/
</style>
